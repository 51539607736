import React, { useRef, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import { ChequeWritten } from '../../../components/Cheque/ChequeWritten'
import { getCookie } from '../../../helpers/auth'
import Scroll from '../../../components/Scroll/Scroll'
import ReactToPrint from 'react-to-print'
import DispatchContext from '../../../DispatchContext'

import Title from '../../../components/Title/Title'

const ViewCheck = ({ match }) => {
	const appDispatch = useContext(DispatchContext)
	const componentRef = useRef()

	const [personalInformation, setPersonalInformation] = useState()
	const [chequeInformation, setChequeInformation] = useState()
	const [branchInformation, setBranchInformation] = useState()
	const [accountInformation, setAccountInformation] = useState()
	const [additionalInformation, setAdditionalInformation] = useState()
	const [appearedInformation, setAppearedInformation] = useState()
	const [hashedSignature, setHashedSignature] = useState()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		getCheckDetails()
	}, [])

	const getCheckDetails = async () => {
		const token = getCookie("token")
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.get(`${process.env.REACT_APP_API}/checks/${match.params.id}`, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
			let checkAdditionalInformation = JSON.parse(response.data.signature).additionalInformation
			checkAdditionalInformation.checkId = match.params.id
			setPersonalInformation(JSON.parse(response.data.signature).personalInformation)
			setChequeInformation(JSON.parse(response.data.signature).chequeInformation)
			setBranchInformation(JSON.parse(response.data.signature).branchInformation)
			setAccountInformation(JSON.parse(response.data.signature).accountInformation)
			setAppearedInformation(JSON.parse(response.data.signature).appearedInformation)
			setAdditionalInformation(checkAdditionalInformation)
			setHashedSignature(response.data.hashed_signature)
			setLoading(true)
		} catch (err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	const printButton = () => {
		return (
			<button className = "btn btn-primary btn-user btn-block">Print the Cheque</button>
			)
	}

	return (
		<Title title = "Check">
		<div className = "checkPageMain">
		{loading &&
		<Scroll>
		<div className = "Cheque_temp">
		<Link to = "/"><button className = "btn btn-warning btn-user btn-block mt-2">Back to Home</button></Link>
		<ReactToPrint
		trigger={() => printButton()}
		content={() => componentRef.current}
		/>
		<ChequeWritten 
			ref={componentRef}
			hashedSignature = { hashedSignature }
			personalInformation = { personalInformation }
			chequeInformation = { chequeInformation }
			branchInformation = { branchInformation }
			accountInformation = { accountInformation }
			additionalInformation = { additionalInformation }
			appearedInformation = { appearedInformation }
		/>
		</div>
		</Scroll>
	}
		</div>
		</Title>
		)
}

export default ViewCheck