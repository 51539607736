import React from 'react'

import './Scroll.css'

const Scroll = ({ children }) => {
	return (
		<div className = "scroll">{ children }</div>
	)
}

export default Scroll