import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { CSSTransition } from "react-transition-group"
import Axios from 'axios'
import { withRouter } from 'react-router-dom'

import './Auth.css'

import DispatchContext from '../../DispatchContext'
import StateContext from '../../StateContext'
import LandingPage from '../../components/LandingPage/LandingPage'
import Title from '../../components/Title/Title'

const Register = ({ history }) => {

	const appDispatch = useContext(DispatchContext)
	const appState = useContext(StateContext)

	useEffect(() => {
		let intended = history.location.state;
		if (appState.loggedIn && appState.user) {
			history.push("/")
		}
	}, [])

	const reMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

	const initialState = {
		name: {
			value: "",
			hasErrors: false,
			message: ""
		},
		email: {
			value: "",
			hasErrors: false,
			message: "",
			isUnique: true,
			checkCount: 0
		},
		password: {
			value: "",
			hasErrors: false,
			message: "",
		},
		password2: {
			value: "",
			hasErrors: false,
			message: ""
		},
		submitCount: 0
	}

	const registerReducer = (draft, action) => {
		switch (action.type) {
			case 'nameImmediately':
			draft.name.hasErrors = false
			draft.name.value = action.value
			if (draft.name.value.length > 32) {
				draft.name.hasErrors = true
				draft.name.message = "Name cannot exceed 32 characters."
			}
			if (draft.name.value && !/^([a-zA-Z ]+)$/.test(draft.name.value)) {
				draft.name.hasErrors = true
				draft.name.message = "Name can only contain letters."
			}
			return

			case "nameAfterDelay":
			if (draft.name.value.length < 3) {
				draft.name.hasErrors = true
				draft.name.message = "Name must be at least 3 characters."
			}
			return

			case "emailImmediately":
			draft.email.hasErrors = false
			draft.email.isUnique = true
			draft.email.value = action.value
			return

			case "emailAfterDelay":
			if (!reMail.test(draft.email.value)) {
				draft.email.hasErrors = true
				draft.email.message = "You must provide a valid email address."
			}
			if (!draft.email.hasErrors && !action.noRequest) {
				draft.email.checkCount++
			}
			return

			case "emailUniqueResults":
			if (action.value) {
				draft.email.hasErrors = true
				draft.email.isUnique = false
				draft.email.message = action.value
			} else {
				draft.email.isUnique = true
			}
			return

			case "passwordImmediately":
			draft.password.hasErrors = false
			draft.password.value = action.value
			return

			case "passwordAfterDelay":
			if (draft.password.value.length < 6) {
				draft.password.hasErrors = true
				draft.password.message = "Password must be at least 6 characters."
			}
			return

			case "password2Immediately":
			draft.password2.hasErrors = false
			draft.password2.value = action.value
			return

			case "password2AfterDelay":
			if (draft.password2.value !== draft.password.value) {
				draft.password2.hasErrors = true
				draft.password2.message = "Passwords do not match."
			}
			return

			case "submitForm":
			if (!draft.name.hasErrors && !draft.email.hasErrors && draft.email.isUnique && !draft.password.hasErrors && !draft.password2.hasErrors) {
				draft.submitCount++
			}
			return
		}
	}

	const [state, dispatch] = useImmerReducer(registerReducer, initialState)

	useEffect(() => {
		if (state.name.value) {
			const delay = setTimeout(() => dispatch({type: "nameAfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.name.value])

	useEffect(() => {
		if (state.email.value) {
			const delay = setTimeout(() => dispatch({type: "emailAfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.email.value])

	useEffect(() => {
		isEmailUnique()
	}, [state.email.checkCount])

	const isEmailUnique = async () => {
		try {
			const response = await Axios.post(`${process.env.REACT_APP_API}/find-email`, {email: state.email.value})
			if (!response.data.unique) {
				dispatch({type: "emailUniqueResults", value: "This email is already taken."})
			}
		} catch(err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (state.password.value) {
			const delay = setTimeout(() => dispatch({type: "passwordAfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.password.value])

	useEffect(() => {
		if (state.password2.value) {
			const delay = setTimeout(() => dispatch({type: "password2AfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.password2.value])

	useEffect(() => {
		if (state.submitCount) {
			userSubmit()
		}
	}, [state.submitCount])

	const userSubmit = async () => {
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.post(`${process.env.REACT_APP_API}/register`, {
				name: state.name.value,
				email: state.email.value,
				password: state.password.value
			})
			console.log(response)
			appDispatch({type: "loadingOff"})
			appDispatch({type: "flashMessage", value: response.data.message})
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
			console.log(err)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch({type: "nameImmediately", value: state.name.value})
		dispatch({type: "nameAfterDelay"})
		dispatch({type: "emailImmediately", value: state.email.value})
		dispatch({type: "emailAfterDelay", noRequest: true})
		dispatch({type: "passwordImmediately", value: state.password.value})
		dispatch({type: "passwordAfterDelay"})
		dispatch({type: "password2Immediately", value: state.password2.value})
		dispatch({type: "password2AfterDelay"})
		if (state.email.value && !state.email.isUnique) {
			dispatch({type: "emailUniqueResults", value: "This email is already taken."})
		}
		dispatch({type: "submitForm"})
	}
	
	return (
		<Title title = "Register">
		<LandingPage>
		<div className="container">

		<div className="card o-hidden border-0 shadow-lg my-5">
		<div className="card-body p-0">

		<div className="row">
		<div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
		<div className="col-lg-7">
		<div className="p-5">
		<div className="text-center">
		<h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
		</div>
		<form className="user" onSubmit = { submitHandler }>
		<div className="form-group row">
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<label>Full Name</label>
		<input onChange = { e => dispatch({type: "nameImmediately", value: e.target.value}) } type="text" className="form-control form-control-user" id="exampleFirstName"
		/>
		<CSSTransition in={state.name.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.name.message}</div>
		</CSSTransition>
		</div>
		<div className="col-sm-6 inputContainer">
		<label>Email</label>
		<input onChange = { e => dispatch({type: "emailImmediately", value: e.target.value}) } type="email" className="form-control form-control-user" id="exampleInputEmail"
		/>
		<CSSTransition in={state.email.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.email.message}</div>
		</CSSTransition>
		</div>
		</div>
		<div className="form-group row">
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<label>Password</label>
		<input onChange = { e => dispatch({type: "passwordImmediately", value: e.target.value}) } type="password" className="form-control form-control-user"
		id="exampleInputPassword"/>
		<CSSTransition in={state.password.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.password.message}</div>
		</CSSTransition>
		</div>
		<div className="col-sm-6 inputContainer">
		<label>Repeat Password</label>
		<input onChange = { e => dispatch({type: "password2Immediately", value: e.target.value}) } type="password" className="form-control form-control-user"
		id="exampleRepeatPassword"/>
		<CSSTransition in={state.password2.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.password2.message}</div>
		</CSSTransition>
		</div>
		</div>
		<button type = "submit" className="btn btn-primary btn-user btn-block">
		Register Account
		</button>
		</form>
		<hr/>
		<div className="text-center">
		<Link className="small" to="/login">Already have an account? Login!</Link>
		</div>
		</div>
		</div>
		</div>
		</div>
		</div>

		</div>
		</LandingPage>
		</Title>
		)
}

export default withRouter(Register)