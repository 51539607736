import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { CSSTransition } from "react-transition-group"
import Axios from 'axios'
import { withRouter } from 'react-router-dom'

import './NewAccount.css'

import { getCookie } from '../../../helpers/auth'
import DispatchContext from '../../../DispatchContext'

import UserPage from '../../UserPage/UserPage'
import Title from '../../../components/Title/Title'
import FileUpload from '../../../components/FileUpload/FileUpload'

const NewAccount = ({ history }) => {
	const appDispatch = useContext(DispatchContext)
	const rePhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

	const initialState = {
		name: {
			value: "",
			hasErrors: false,
			message: "",
		},
		province: {
			value: "",
			hasErrors: false,
			message: "",
		},
		city: {
			value: "",
			hasErrors: false,
			message: "",
		},
		address: {
			value: "",
			hasErrors: false,
			message: "",
		},
		postalcode: {
			value: "",
			hasErrors: false,
			message: "",
		},
		phone: {
			value: "",
			hasErrors: false,
			message: "",
		},
		logo: {
			url: "",
			public_id: "",
		},
		check_num: {
			value: "",
			hasErrors: false,
			message: "",
		},
		finance_num: {
			value: "",
			hasErrors: false,
			message: "",
		},
		branch_num: {
			value: "",
			hasErrors: false,
			message: "",
		},
		account_num: {
			value: "",
			hasErrors: false,
			message: "",
		},
		branch_postalcode: {
			value: "",
			hasErrors: false,
			message: "",
		},
		bank_id: "",
		branch_id: "",
		pageStates: [
			{
				"name": "Alberta",
				"abbreviation": "AB"
			},
			{
				"name": "British Columbia",
				"abbreviation": "BC"
			},
			{
				"name": "Manitoba",
				"abbreviation": "MB"
			},
			{
				"name": "New Brunswick",
				"abbreviation": "NB"
			},
			{
				"name": "Newfoundland and Labrador",
				"abbreviation": "NL"
			},
			{
				"name": "Northwest Territories",
				"abbreviation": "NT"
			},
			{
				"name": "Nova Scotia",
				"abbreviation": "NS"
			},
			{
				"name": "Nunavut",
				"abbreviation": "NU"
			},
			{
				"name": "Ontario",
				"abbreviation": "ON"
			},
			{
				"name": "Prince Edward Island",
				"abbreviation": "PE"
			},
			{
				"name": "Quebec",
				"abbreviation": "QC"
			},
			{
				"name": "Saskatchewan",
				"abbreviation": "SK"
			},
			{
				"name": "Yukon Territory",
				"abbreviation": "YT"
			}
		],
		submitCount: 0
	}

	const loginReducer = (draft, action) => {
		switch (action.type) {
			case "nameImmediately":
				draft.name.hasErrors = false
				draft.name.value = action.value
				return

			case "nameAfterDelay":
				if (draft.name.value.length < 1) {
					draft.name.hasErrors = true
					draft.name.message = "Name is required."
				}
				return

			case "provinceImmediately":
				draft.province.hasErrors = false
				draft.province.value = action.value
				return

			case "cityImmediately":
				draft.city.hasErrors = false
				draft.city.value = action.value
				return

			case "addressImmediately":
				draft.address.hasErrors = false
				draft.address.value = action.value
				return

			case "postalcodeImmediately":
				draft.postalcode.hasErrors = false
				draft.postalcode.value = action.value
				return

			case "logoImmediately":
				draft.logo.url = action.url
				draft.logo.public_id = action.public_id
				return

			case "phoneImmediately":
				draft.phone.hasErrors = false
				draft.phone.value = action.value
				return

			case "phoneAfterDelay":
				if (draft.phone.value.length && !rePhone.test(draft.phone.value)) {
					draft.phone.hasErrors = true
					draft.phone.message = "Valid phone number format is +x (xxx) xxx-xxxx"
				}
				return

			case "check_numImmediately":
				draft.check_num.hasErrors = false
				draft.check_num.value = action.value
				return

			case "check_numAfterDelay":
				if (draft.check_num.value.length < 1) {
					draft.check_num.hasErrors = true
					draft.check_num.message = "Check number is required."
				}
				if (draft.check_num.value.length > 4) {
					draft.check_num.hasErrors = true
					draft.check_num.message = "Check number must be less than 4 digits."
				}
				return

			case "finance_numImmediately":
				draft.finance_num.hasErrors = false
				draft.finance_num.value = action.value
				return

			case "finance_numAfterDelay":
				if (draft.finance_num.value.length != 3) {
					draft.finance_num.hasErrors = true
					draft.finance_num.message = "Financial institution number must be 3 digits."
				}
				return

			case "branch_numImmediately":
				draft.branch_num.hasErrors = false
				draft.branch_num.value = action.value
				return

			case "branch_numAfterDelay":
				if (draft.branch_num.value.length != 5) {
					draft.branch_num.hasErrors = true
					draft.branch_num.message = "Financial institution number must be 5 digits."
				}
				return

			case "account_numImmediately":
				draft.account_num.hasErrors = false
				draft.account_num.value = action.value
				return

			case "account_numAfterDelay":
				if (draft.account_num.value.length != 7) {
					draft.account_num.hasErrors = true
					draft.account_num.message = "Account number must be 7 digits."
				}
				return

			case "branch_postalcodeImmediately":
				draft.branch_postalcode.hasErrors = false
				draft.branch_postalcode.value = action.value
				return

			case "branch_postalcodeAfterDelay":
				if (draft.branch_postalcode.value.length > 12) {
					draft.branch_postalcode.hasErrors = true
					draft.branch_postalcode.message = "Branch postal code is not greater than 12 characters."
				}
				return

			case "branch_idImmediately":
				draft.branch_id = action.value
				return

			case "bankIdImmediately":
				draft.bank_id = action.value
				return

			case "submitForm":
				if (!draft.name.hasErrors && !draft.province.hasErrors && !draft.city.hasErrors && !draft.address.hasErrors && !draft.postalcode.hasErrors && !draft.phone.hasErrors && !draft.check_num.hasErrors && !draft.finance_num.hasErrors && !draft.branch_num.hasErrors && !draft.account_num.hasErrors && !draft.branch_postalcode.hasErrors) {
					draft.submitCount++
				}
				return
		}
	}

	const [state, dispatch] = useImmerReducer(loginReducer, initialState)

	useEffect(() => {
		findUser()
	}, [])

	const findUser = async () => {
		const token = getCookie("token")
		try {
			appDispatch({ type: "loadingOn" })
			let response = await Axios.get(`${process.env.REACT_APP_API}/info`, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({ type: "loadingOff" })
			if (response.data) {
				dispatch({ type: "nameImmediately", value: response.data.name })
				dispatch({ type: "provinceImmediately", value: response.data.province })
				dispatch({ type: "cityImmediately", value: response.data.city })
				dispatch({ type: "addressImmediately", value: response.data.address })
				dispatch({ type: "postalcodeImmediately", value: response.data.postalcode })
				dispatch({ type: "phoneImmediately", value: response.data.phone })
				dispatch({ type: "logoImmediately", url: response.data.logo.url, public_id: response.data.logo.public_id })
			}
		} catch (err) {
			appDispatch({ type: "loadingOff" })
			console.log(err)
		}
	}

	useEffect(() => {
		if (state.name.value) {
			const delay = setTimeout(() => dispatch({ type: "nameAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.name.value])

	useEffect(() => {
		if (state.phone.value) {
			const delay = setTimeout(() => dispatch({ type: "phoneAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.phone.value])

	useEffect(() => {
		if (state.check_num.value) {
			const delay = setTimeout(() => dispatch({ type: "check_numAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.check_num.value])

	useEffect(() => {
		if (state.finance_num.value) {
			const delay = setTimeout(() => dispatch({ type: "finance_numAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.finance_num.value])

	useEffect(() => {
		if (state.branch_num.value) {
			const delay = setTimeout(() => dispatch({ type: "branch_numAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.branch_num.value])

	useEffect(() => {
		if (state.account_num.value) {
			const delay = setTimeout(() => dispatch({ type: "account_numAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.account_num.value])


	useEffect(() => {
		if (state.branch_postalcode.value) {
			const delay = setTimeout(() => dispatch({ type: "branch_postalcodeAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.branch_postalcode.value])

	useEffect(() => {
		if (state.submitCount) {
			accountSubmit()
		}
	}, [state.submitCount])

	const accountSubmit = async () => {
		const token = getCookie("token")
		console.log(state.branch_id)
		try {
			appDispatch({ type: "loadingOn" })
			let response = await Axios.post(`${process.env.REACT_APP_API}/account/new`, {
				name: state.name.value,
				province: state.province.value,
				city: state.city.value,
				address: state.address.value,
				postalcode: state.postalcode.value,
				logo: { url: state.logo.url, public_id: state.logo.public_id },
				phone: state.phone.value,
				check_num: state.check_num.value,
				branch_num: state.branch_num.value,
				account_num: state.account_num.value,
				branch_postalcode: state.branch_postalcode.value,
				bank_id: state.bank_id,
				branch_id: state.branch_id,
			}, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({ type: "loadingOff" })
			if (response.data._id) {
				appDispatch({ type: "flashMessage", value: "Congrats! New account has been successfully created" })
				history.push('/accounts')
			}
		} catch (err) {
			appDispatch({ type: "loadingOff" })
			appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
			console.log(err)
		}
	}

	const findBranch = async (TransitNumber) => {
		try {
			appDispatch({ type: "loadingOn" })
			let res = await Axios.post(`${process.env.REACT_APP_API}/branchid`, { TransitNumber })
			appDispatch({ type: "loadingOff" })
			if (res.data.branchId) {
				return res.data.branchId
			}
		} catch (err) {
			appDispatch({ type: "loadingOff" })
			appDispatch({ type: "errorMessage", value: "The pair of Branch Number and Institution number you have entered is not found in our system." })
			console.log(err)
		}
	}

	const findBank = async (InstitutionNumber) => {
		try {
			appDispatch({ type: "loadingOn" })
			let res = await Axios.get(`${process.env.REACT_APP_API}/bank/${InstitutionNumber}`)
			appDispatch({ type: "loadingOff" })
			if (res.data._id) {
				return res.data._id
			}
		} catch (err) {
			appDispatch({ type: "loadingOff" })
			appDispatch({ type: "errorMessage", value: "The Institution number you have entered is not found in our system." })
			console.log(err)
		}
	}

	const submitHandler = async (e) => {
		e.preventDefault()
		dispatch({ type: "nameImmediately", value: state.name.value })
		dispatch({ type: "nameAfterDelay" })
		dispatch({ type: "provinceImmediately", value: state.province.value })
		dispatch({ type: "cityImmediately", value: state.city.value })
		dispatch({ type: "addressImmediately", value: state.address.value })
		dispatch({ type: "postalcodeImmediately", value: state.postalcode.value })
		dispatch({ type: "logoImmediately", url: state.logo.url, public_id: state.logo.public_id })
		dispatch({ type: "phoneImmediately", value: state.phone.value })
		dispatch({ type: "phoneAfterDelay" })
		dispatch({ type: "check_numImmediately", value: state.check_num.value })
		dispatch({ type: "check_numAfterDelay" })
		dispatch({ type: "finance_numImmediately", value: state.finance_num.value })
		dispatch({ type: "finance_numAfterDelay" })
		dispatch({ type: "branch_numImmediately", value: state.branch_num.value })
		dispatch({ type: "branch_numAfterDelay" })
		dispatch({ type: "account_numImmediately", value: state.account_num.value })
		dispatch({ type: "account_numAfterDelay" })
		dispatch({ type: "branch_postalcodeImmediately", value: state.branch_postalcode.value })
		dispatch({ type: "branch_postalcodeAfterDelay" })

		// const bankId = await findBank(state.finance_num.value)
		// if (bankId && bankId.length) {
		dispatch({ type: "bankIdImmediately", value: state.finance_num.value })
		// const TransitNumber = `${state.branch_num.value}-${state.finance_num.value}`
		// const branchId = await findBranch(TransitNumber)
		// if (branchId && branchId.length) {
		dispatch({ type: "branch_idImmediately", value: state.branch_num.value })
		// console.log(branchId)
		dispatch({ type: "submitForm" })
		// } 
		// }
	}

	const logoRemoveHandler = () => {
		dispatch({ type: "logoImmediately", url: "", public_id: "" })
	}

	return (
		<Title title="New Account">
			<UserPage page="newAccount">
				<div className="container">

					<div className="card o-hidden border-0 shadow-lg my-5">
						<div className="card-body p-0">

							<div className="row">
								<div className="col-lg-12">
									<div className="p-5">
										<div className="text-center">
											<h1 className="h4 text-gray-900 mb-4">Create New Account!</h1>
										</div>
										<form className="user" onSubmit={submitHandler}>
											<div className="form-group row">
												<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
													<label>Current Check Number</label>
													<input onChange={e => dispatch({ type: "check_numImmediately", value: e.target.value })} type="number" className="form-control form-control-user" step="0.01"
													/>
													<CSSTransition in={state.check_num.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.check_num.message}</div>
													</CSSTransition>
												</div>
												<div className="col-sm-6 inputContainer">

													<label>Financial Institution Number</label>
													<input onChange={e => dispatch({ type: "finance_numImmediately", value: e.target.value })} type="number" step="0.01" className="form-control form-control-user"
													/>
													<CSSTransition in={state.finance_num.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.finance_num.message}</div>
													</CSSTransition>
												</div>
											</div>

											<div className="form-group row">
												<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">

													<label>Branch Number</label>
													<input onChange={e => dispatch({ type: "branch_numImmediately", value: e.target.value })} type="number" step="0.01" className="form-control form-control-user"
													/>
													<CSSTransition in={state.branch_num.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.branch_num.message}</div>
													</CSSTransition>
												</div>
												<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">

													<label>Account Number</label>
													<input onChange={e => dispatch({ type: "account_numImmediately", value: e.target.value })} type="number" step="0.01" className="form-control form-control-user"
													/>
													<CSSTransition in={state.account_num.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.account_num.message}</div>
													</CSSTransition>
												</div>
											</div>

											<div className="form-group row">
												<div className="col-sm-12 mb-3 mb-sm-0 inputContainer">

													<label>Branch Postal Code</label>
													<input onChange={e => dispatch({ type: "branch_postalcodeImmediately", value: e.target.value })} type="text" className="form-control form-control-user"
														id="inputZip" />
													<CSSTransition in={state.branch_postalcode.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.branch_postalcode.message}</div>
													</CSSTransition>
												</div>

											</div>

											<div className="form-group row">
												<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">

													<label>Name</label>
													<input onChange={e => dispatch({ type: "nameImmediately", value: e.target.value })} type="text" className="form-control form-control-user" id="exampleFirstName"
														value={state.name.value} />
													<CSSTransition in={state.name.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.name.message}</div>
													</CSSTransition>
												</div>
												<div className="col-sm-6 inputContainer">

													<label>Province</label>
													<select onChange={e => dispatch({ type: "provinceImmediately", value: e.target.value })} className="form-control form-select"
														value={state.province.value}>
														<option value="-1">Please choose a province</option>
														{state.pageStates.map((prov, idx) => {
															return <option key={idx} value={prov.abbreviation}>{prov.abbreviation}</option>
														})}
													</select>
												</div>
											</div>

											<div className="form-group row">
												<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">

													<label>City</label>
													<input onChange={e => dispatch({ type: "cityImmediately", value: e.target.value })} type="text" className="form-control form-control-user"
														id="inputCity" value={state.city.value} />
													<CSSTransition in={state.city.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.city.message}</div>
													</CSSTransition>
												</div>
												<div className="col-sm-6 inputContainer">

													<label>Address</label>
													<input onChange={e => dispatch({ type: "addressImmediately", value: e.target.value })} type="text" className="form-control form-control-user"
														id="inputAddress1" value={state.address.value} />
													<CSSTransition in={state.address.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.address.message}</div>
													</CSSTransition>
												</div>
											</div>

											<div className="form-group row">
												<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">

													<label>Postal Code</label>
													<input onChange={e => dispatch({ type: "postalcodeImmediately", value: e.target.value })} type="text" className="form-control form-control-user"
														id="inputZip" value={state.postalcode.value} />
													<CSSTransition in={state.postalcode.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.postalcode.message}</div>
													</CSSTransition>
												</div>

												<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">

													<label>Phone Number</label>
													<input onChange={e => dispatch({ type: "phoneImmediately", value: e.target.value })} type="text" className="form-control form-control-user"
														id="inputPhone" value={state.phone.value} />
													<CSSTransition in={state.phone.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
														<div className="alert small alert--warning">{state.phone.message}</div>
													</CSSTransition>
												</div>

											</div>

											<div className="form-group">
												<div className="col-sm-12 mb-3 mb-sm-0 LogoHandler">
													<FileUpload dispatch={dispatch} txt="Logo" />
													{state.logo.url && state.logo.url.length ? (
														<div className="LogoMessage">
															<img width="80" height="80" className="img-profile rounded-circle smallLogo" src={state.logo.url} />
															<div onClick={logoRemoveHandler} className="deleteIcon"><i className="fas fa-times"></i></div>
														</div>
													) : ("")
													}

												</div>
											</div>

											<button type="submit" className="btn btn-primary btn-user btn-block">
												Submit
											</button>

										</form>


									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</UserPage>
		</Title>
	)
}

export default withRouter(NewAccount)