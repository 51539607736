import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { CSSTransition } from "react-transition-group"
import Axios from 'axios'
import { withRouter } from 'react-router-dom'

import { getCookie } from '../../../helpers/auth'
import DispatchContext from '../../../DispatchContext'

import UserPage from '../../UserPage/UserPage'
import Title from '../../../components/Title/Title'

const NewPayee = ({ history }) => {
	const appDispatch = useContext(DispatchContext)

	const reMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	const initialState = {
		name: {
			value: "",
			hasErrors: false,
			message: "",
		},
		email: {
			value: "",
			hasErrors: false,
			message: "",
			isRegistred: true,
			checkCount: 0,
		},
		payeeId: '',
		submitCount: 0
	}

	const loginReducer = (draft, action) => {
		switch (action.type) {
			case "nameImmediately":
			draft.name.hasErrors = false
			draft.name.value = action.value
			return

			case "nameAfterDelay":
			if (draft.name.value.length < 1) {
				draft.name.hasErrors = true
				draft.name.message = "Name is required."
			}
			return

			case "emailImmediately":
			draft.email.hasErrors = false
			draft.email.value = action.value
			return

			case "emailAfterDelay":
			if (!reMail.test(draft.email.value)) {
				draft.email.hasErrors = true
				draft.email.message = "You must provide a valid email address."
			}
			if (!draft.email.hasErrors && !action.noRequest) {
				draft.email.checkCount++
			}
			return

			case "emailRegistredResults":
			if (action.value) {
				draft.email.hasErrors = true
				draft.email.isRegistred = false
				draft.email.message = action.value
			} else {
				draft.email.isRegistred = true
			}
			return

			case "payeeIdlImmediately":
			draft.payeeId = action.value
			return

			case "submitForm":
			if (!draft.name.hasErrors && !draft.email.hasErrors) {
				draft.submitCount++
			}
			return
		}
	}

	const [state, dispatch] = useImmerReducer(loginReducer, initialState)

	useEffect(() => {
		if (state.name.value) {
			const delay = setTimeout(() => dispatch({type: "nameAfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.name.value])

	useEffect(() => {
		if (state.email.value) {
			const delay = setTimeout(() => dispatch({type: "emailAfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.email.value])

	useEffect(() => {
		if (state.email.checkCount) {
			isEmailRegistred()
		}
	}, [state.email.checkCount])

	const isEmailRegistred = async () => {
		try {
			const response = await Axios.post(`${process.env.REACT_APP_API}/find-email`, {email: state.email.value})
			if (response.data.unique) {
				dispatch({type: "emailRegistredResults", value: "This email is not registred with our app."})
			} else {
				dispatch({type: "payeeIdlImmediately", value: response.data.id})
			}
		} catch(err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (state.submitCount) {
			payeeSubmit()
		}
	}, [state.submitCount])

	const payeeSubmit = async () => {
		const token = getCookie("token")
		try {
			
		} catch (err) {
			console.log(err)
		}
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.post(`${process.env.REACT_APP_API}/payee/new`, {
				name: state.name.value,
				email: state.email.value,
				payee_id: state.payeeId
			}, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
			if (response.data._id) {
				appDispatch({type: "flashMessage", value: "Congrats! New payee has been successfully created"})
				history.push('/payees')
			}
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
			console.log(err)
		}
	}

	const submitHandler = async (e) => {
		e.preventDefault()
		dispatch({type: "nameImmediately", value: state.name.value})
		dispatch({type: "nameAfterDelay"})
		dispatch({type: "emailImmediately", value: state.email.value})
		dispatch({type: "emailAfterDelay"})
		dispatch({type: "submitForm"})
	}

	return (
		<Title title = "New Payee">
		<UserPage page = "newPayee">
		<div className="container">

		<div className="card o-hidden border-0 shadow-lg my-5">
		<div className="card-body p-0">

		<div className="row">
		<div className="col-lg-12">
		<div className="p-5">
		<div className="text-center">
		<h1 className="h4 text-gray-900 mb-4">Create New Payee!</h1>
		</div>
		<form className="user" onSubmit = { submitHandler }>
		<div className="form-group row">
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<label>Name</label>
		<input onChange = { e => dispatch({type: "nameImmediately", value: e.target.value}) } type="text" className="form-control form-control-user"
		/>
		<CSSTransition in={state.name.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.name.message}</div>
		</CSSTransition>
		</div>
		<div className="col-sm-6 inputContainer">

		<label>Email</label>
		<input onChange = { e => dispatch({type: "emailImmediately", value: e.target.value}) } type="email" className="form-control form-control-user"
		/>
		<CSSTransition in={state.email.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.email.message}</div>
		</CSSTransition>
		</div>
		</div>

		<button type = "submit" className="btn btn-primary btn-user btn-block">
		Submit
		</button>
		
		</form>
		
		
		</div>
		</div>
		</div>
		</div>
		</div>

		</div>
		</UserPage>
		</Title>
		)
}

export default withRouter(NewPayee)