import React, { useState, useContext } from 'react'
import Axios from 'axios'
import Resizer from "react-image-file-resizer"

import { getCookie } from '../../helpers/auth'
import DispatchContext from '../../DispatchContext'

const FileUpload = ({dispatch, txt}) => {
  const appDispatch = useContext(DispatchContext)
  
	const fileUploadAndResize = (e) => {
    // console.log(e.target.files);
    // resize
    const token = getCookie("token")
    let file = e.target.files[0];
    if (file) {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        "PNG",
        100,
        0,
        (uri) => {
            // console.log(uri);
            appDispatch({type: "loadingOn"})
            Axios.post(`${process.env.REACT_APP_API}/uploadimages`, {image: uri}, {
              headers: {
                Authorization: token
              }
            })
            .then((res) => {
              appDispatch({type: "loadingOff"})
              console.log("IMAGE UPLOAD RES DATA", res);
              dispatch({type: "logoImmediately", url: res.data.url, public_id: res.data.public_id})
            })
            .catch((err) => {
              appDispatch({type: "loadingOff"})
              appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
              console.log("CLOUDINARY UPLOAD ERR", err);
            });
          },
          "base64"
          );
      


    }
    // send back to server to upload to cloudinary
    // set url to images[] in the parent component state - ProductCreate
  };

	return (
		<React.Fragment>
		<div className="btn btn-light btn-icon-split" onClick = {() => document.getElementById('file').click()}>
		<span class="icon text-gray-600">
		<i class="fas fa-arrow-right"></i>
		</span>
		<span class="text text-gray-700">Pick a {txt}</span>
		</div>
		<input
		type="file"
		className = "HiddenInput"
		name = "file"
		id = "file"
		accept="images/*"
		onChange={fileUploadAndResize}
		/>
		</React.Fragment>
		)
}

export default FileUpload