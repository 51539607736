import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import './Home.css'

import StateContext from '../../StateContext'
import DispatchContext from '../../DispatchContext'
import UserPage from '../UserPage/UserPage'
import Title from '../../components/Title/Title'
import Footer from '../../components/Footer/Footer'

import { getCookie } from '../../helpers/auth'

const Home = () => {
	const appState = useContext(StateContext)
	const appDispatch = useContext(DispatchContext)

	const [newChecksNum, setNewChecksNum] = useState()
	useEffect(() => {
		getChecks()
	}, [])

	const getChecks = async () => {
		const token = getCookie("token")
		let newChecks = 0
		try {
			appDispatch({type: "loadingOn"})
			let userChecks = await Axios.get(`${process.env.REACT_APP_API}/checks-dep`, { headers: {
				Authorization: token 
			}})
			appDispatch({type: "loadingOff"})
			if (userChecks.data.checks) {
				userChecks.data.checks.map(check => {
					if (!check.viewed) {
						newChecks++
					}
				})
				setNewChecksNum(newChecks)
			}
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	const getText = () => {
		if (!newChecksNum) {
			return "No new check has been deposited to your profile."
		} else if (newChecksNum === 1) {
			return <React.Fragment><span className = "red">{newChecksNum}</span> new check has been deposited to your profile; You can print that check and deposit it to your bank account.</React.Fragment>
		} else {
			return <React.Fragment><span className = "red">{newChecksNum}</span> new checks have been deposited to your profile; You can print and deposit them to your bank account.</React.Fragment>
		}
	}

	return (
		<Title title = "Dashboard">
		<UserPage page = "home">
			<div class="card shadow mb-4 paragraph">
				<div class="card-header py-3">
					<h6 class="m-0 font-weight-bold text-primary">Welcome {appState.user.name}</h6>
				</div>
				<div class="card-body">
					{getText()}
					<div className = "mt-4">
						<a href="#" className="btn btn-primary btn-icon-split">
							<span className="icon text-white-50">
								<i className="fas fa-money-check-alt"></i>
							</span>
							<Link to = "deposited-checks" className="btn btn-primary btn-user">Deposited Checks</Link>
						</a>
					</div>
				</div>
			</div>
		</UserPage>
		</Title>
		)
}

export default Home