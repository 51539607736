import React, { useState, useEffect, useContext } from 'react'
import jwt from 'jsonwebtoken'
import Axios from 'axios'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import './Auth.css'

import DispatchContext from '../../DispatchContext'
import LandingPage from '../../components/LandingPage/LandingPage'
import Title from '../../components/Title/Title'

const Activate = ({ match, history }) => {
	const appDispatch = useContext(DispatchContext)

	const [name, setName] = useState()
	let [token, setToken] = useState(false)

	useEffect(() => {
		setToken(match.params.token)
		if (token) {
			try {
				setName(jwt.decode(token).name)

			} catch (err) {
				console.log(err)
			}
		} 
	}, [token])
	const activateHandler = async () => {
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.post(`${process.env.REACT_APP_API}/activation`, { token })
			appDispatch({type: "loadingOff"})
			appDispatch({type: "flashMessage", value: response.data.message})
			history.push('/login')
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
			console.log(err)
		}
	}

	return (
		<Title title = "Activate Account">
		<LandingPage>
		<div id="logreg-forms">
		<div className="form-signup">
		<h3 className= "headText">Welcome { name }</h3>
		<p className = "activatePar">We're excited to have you get started. First, you need to confirm your account. Just press the button below.</p>
		<button className="btn btn-primary" onClick = { activateHandler }>Activate Account</button>
		<Link to="/register" id="cancel_reset" className = "mb-2"><i className="fas fa-angle-left"></i> Sign up again </Link>
		</div>
		</div>
		</LandingPage>
		</Title>
	)
}

export default withRouter(Activate)