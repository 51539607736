import React, { useEffect } from 'react'

const Title = ({ title, children }) => {
	useEffect(() => {
		document.title = `${ title } - CryptoChecks`
		window.scrollTo(0, 0)
	}, [])

	return (
		<React.Fragment>
			{ children }
		</React.Fragment>
	)
}

export default Title