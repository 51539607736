import React from 'react'

import bgImg from './bg-masthead.jpg'
// import bgImg from './bg-img2.jpg'

const LandingPage = ({ children }) => {
	return (
		<header className="header">
		<img src={bgImg} alt="Background Image" className = "bgImg"/>
            <div className="container h-100">
                <div className="row h-100 align-items-center justify-content-center text-center">
                    { children }
                </div>
            </div>
        </header>
	)
}

export default LandingPage