import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import UserPage from '../UserPage/UserPage'
import { getCookie } from '../../helpers/auth'
import StateContext from '../../StateContext'
import Title from '../../components/Title/Title'
import DispatchContext from '../../DispatchContext'

const MyInformation = () => {
	const appDispatch = useContext(DispatchContext)
	
	useEffect(() => {
		getInfo()
	}, [])

	const [name, setName] = useState('N.A.')
	const [email, setEmail] = useState('N.A.')
	const [province, setProvince] = useState('N.A.')
	const [city, setCity] = useState('N.A.')
	const [address, setAddress] = useState('N.A.')
	const [postalcode, setPostalcode] = useState('N.A.')
	const [phone, setPhone] = useState('N.A.')
	const [logo, setLogo] = useState('N.A.')

	const getInfo = async () => {
		const token = getCookie("token")
		try {
			appDispatch({type: "loadingOn"})
			let info = await Axios.get(`${process.env.REACT_APP_API}/info`, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
			setName(info.data.name)
			setEmail(info.data.email)
			if (info.data.province) {
				setProvince(info.data.province)
			}
			if (info.data.city) {
				setCity(info.data.city)
			}
			if (info.data.address) {
				setAddress(info.data.address)
			}
			if (info.data.postalcode) {
				setPostalcode(info.data.postalcode)
			}
			if (info.data.phone) {
				setPhone(info.data.phone)
			}
			if (info.data.logo) {
				if (info.data.logo.url && info.data.logo.url.length) {
					setLogo(info.data.logo.url)
				}
			}
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	return (
		<Title title = "My Information">
		<UserPage page = "info">
		<div className = "cardsContainer">
		<div class="card shadow mb-4 paragraph single-card">
		<div class="card-header py-3">
		<h6 class="m-0 font-weight-bold text-primary">Information</h6>
		</div>
		<div class="card-body">
		<div class="table-responsive">
		<table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
		<tbody>
		<tr>
		<td>Full Name</td>
		<td>{name}</td>
		</tr>

		<tr>
		<td>Email</td>
		<td>{email}</td>
		</tr>

		<tr>
		<td>Province</td>
		<td>{province}</td>
		</tr>

		<tr>
		<td>City</td>
		<td>{city}</td>
		</tr>

		<tr>
		<td>Address</td>
		<td>{address}</td>
		</tr>

		<tr>
		<td>Postal Code</td>
		<td>{postalcode}</td>
		</tr>

		<tr>
		<td>Phone</td>
		<td>{phone}</td>
		</tr>

		<tr>
		<td>Photo</td>
		<td>{logo && logo.length > 5 ? <img width = "80" height = "80" className="img-profile rounded-circle smallLogo" src={logo}/> : `N.A.`}</td>
		</tr>

		</tbody>
		</table>
		</div>
		<hr />
		<div className = "mt-4 groupBtns">
		<Link to="/info/update" className="btn btn-primary btn-icon-split groupBtns_btn">
		<span className="icon text-white-50">
		<i className="fa fa-edit" aria-hidden="true"></i>
		</span>
		<span className="text text--iconBtn" target="_blank">Update Information</span>
		</Link>
		</div>
		</div>
		</div>
		</div>
		</UserPage>
		</Title>
		)
}

export default MyInformation