import React, { Component } from 'react'
import QRCode from 'qrcode.react'

import './Cheque.css'

export class ChequeWritten extends Component {

  constructor(props) {
    super(props)
    this.state = {
      chequeQRInfo: {
        checkNum: '',
        payee: '',
        amount: '',
        date: ''
      },
      // payerQRInfo: {
      //   id: '',
      //   accountNum: '',
      //   transitNum: '',
      //   institutionNum: ''
      // }
      payerQRInfo: ''
    }
  }

  componentDidMount() {
    this.setState(chequeQRInfo => {
      return {chequeQRInfo: {
        checkNum: this.props.chequeInformation.chequeNumber,
        payee: this.props.chequeInformation.payee,
        amount: this.props.chequeInformation.amount,
        date: this.props.chequeInformation.date,
      }}
    })

    // this.setState(payerQRInfo => {
    //   return {payerQRInfo: {
    //     id: this.props.personalInformation.id,
    //     accountNum: this.props.accountInformation.accountNumber,
    //     transitNum: this.props.branchInformation.branchNum,
    //     institutionNum: this.props.accountInformation.institutionNumber
    //   }}
    // })

    this.setState(payerQRInfo => {
      return {payerQRInfo: `${this.props.personalInformation.id}, ${this.props.accountInformation.accountNumber}, ${this.props.branchInformation.branchNum}, ${this.props.accountInformation.institutionNumber}, ${this.props.chequeInformation.chequeNumber}, ${this.props.chequeInformation.payee}, ${this.addZeroes(this.props.chequeInformation.amount)}, ${this.props.chequeInformation.date}`}
    })
  }

  addStars (word) {
    let value = `**${word}**`
    return value
  }

  dateFormatHandler (num) {
    if (num === "1") {
      return "DD-MM-YYYY"
    }
    if (num === "2") {
      return "MM-DD-YYYY"
    }
  }

  addZeroes(num) {
    // Convert input string to a number and store as a variable.
      var value = Number(num);      
    // Split the input string into two arrays containing integers/decimals
      var res = num.split(".");     
    // If there is no decimal point or only one decimal place found.
      if(res.length === 1 || res[1].length < 3) { 
    // Set the number to two decimal places
          value = value.toFixed(2);
      }
  // Return updated or original number.
    return value;
  }

  componentDidUpdate() {
    console.log(JSON.stringify(JSON.stringify(this.state.chequeQRInfo)))
    console.log(JSON.stringify(JSON.stringify(this.state.payerQRInfo)))
    console.log(this.props)
  }

  
  render() {
    console.log("Props in cheque written are: ", this.props)
    return (
      <div className = "chequeLetter">
      <div className = "chequeTemp">
      <div className = "appAd">
      <div>Digitally Verifiable @ cryptochecks.org</div>
      <div>cryptochecks.org</div>
      <div>Verify the check @ cryptochecks.org</div>
      </div>
      <div className = "signQR"><QRCode className = "signQR" size = "600" value = {this.props.hashedSignature}/></div>
      <div className = "signText">Digital Signature: {this.props.hashedSignature}</div>
      <div className = "chequeTemp_user_info" style={{backgroundImage: `url(${this.props.chequeInformation.templateUrl})`}}>
        <div className = "chequeTemp_top">
          <div className = "chequeTemp_personInfo">
            {this.props.appearedInformation.logo && this.props.personalInformation.logo && this.props.personalInformation.logo.url && this.props.personalInformation.logo.url.length ? (<div><img src = { this.props.personalInformation.logo.url } className = "chequeTemp_personInfo_Logo"/></div>) : ('')}
            <div>
            <div className = "chequeTemp_personInfo_phone">Payer Id: { this.props.personalInformation.id }</div>
            <div className = "chequeTemp_personInfo_name">{ this.props.appearedInformation.name && this.props.personalInformation.name && this.props.personalInformation.name.length && this.props.personalInformation.name }</div>
            <div className = "chequeTemp_personInfo_address">{ this.props.appearedInformation.address && <React.Fragment> {this.props.personalInformation.address && this.props.personalInformation.address.length && this.props.personalInformation.address }</React.Fragment>}</div>
            <div className = "chequeTemp_personInfo_phone">{ this.props.appearedInformation.address && <React.Fragment> { this.props.personalInformation.province && this.props.personalInformation.province.length && this.props.personalInformation.province } { this.props.personalInformation.city && this.props.personalInformation.city.length && this.props.personalInformation.city } </React.Fragment>} { this.props.appearedInformation.postalcode && this.props.personalInformation.postalCode && this.props.personalInformation.postalCode.length && this.props.personalInformation.postalCode } { this.props.appearedInformation.phone && this.props.personalInformation.phone && this.props.personalInformation.phone.length && this.props.personalInformation.phone }</div>
            </div>
          </div>
          <div className = "QR_Info">
          <div className = "QRContainer">
          <div className = "QRTitle">Check Info</div>
          <div className = "chequeTemp_id"><QRCode value = { this.state.payerQRInfo } size = "600"/></div>
          </div>
          </div>
          <div className = "chequeTemp_topRight">
            <div className = "chequeTemp_chequeNum">
              <div className = "chequeNum">{this.props.chequeInformation.chequeNumber}</div>
            </div>
            <div className = "chequeTemp_Date">
              <span>Date</span>
              <div className = "dateFormat">
                <input readOnly type="text" className = "inputField--line inputField--date" value = {this.props.chequeInformation.date}/>
                <div className = "dateFormatHolder text-gray-700">
                {this.dateFormatHandler(this.props.chequeInformation.dateFormat)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className = "chequeTemp_Payee">
          <div>Pay to the</div>
          <div className = "chequeTemp_Payee_2ndLine">
            <div>
              Order of 
              <input readOnly value = {this.addStars(this.props.chequeInformation.payee)} type="text" className = "inputField--line inputField--line--long" placeholder = "Payee Information"/>
            </div>
            <div className = "chequeTemp_amount">
              $
              <input readOnly value = {this.addStars(this.addZeroes(this.props.chequeInformation.amount))} type="text" className = "inputField--simple" placeholder = "Amount in digits"/> 
            </div>
          </div>
          <div className = "chequeTemp_amount_word">
            <input readOnly value = {this.addStars(this.props.chequeInformation.amountWord)} type="text" className = "inputField--line inputField--line--longest" placeholder = "Amount in words"/>
            Dollars
          </div>
        </div>
        <div className = "chequeTemp_bottom">
          <div className = "chequeTemp_bottom_left">
            For 
            <input readOnly type="text" className = "inputField--line inputField--line--for" value = {this.props.additionalInformation.memo}/>
          </div>
          <div className = "chequeTemp_bottom_right">
            Signature
            <input type="text" className = "inputField--line inputField--line--for--right" readOnly/>
          </div>
        </div>
        </div>
        <div className = "chequeTemp_clearBand">
          <div className = "micr_space"></div>
          <div className = "micr_printing">
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char">C</div>
            <div className="micr_char">{this.props.chequeInformation.chequeNumber[0]}</div>
            <div className="micr_char">{this.props.chequeInformation.chequeNumber[1]}</div>
            <div className="micr_char">{this.props.chequeInformation.chequeNumber[2]}</div>
            <div className="micr_char">{this.props.chequeInformation.chequeNumber[3]}</div>
            <div className="micr_char">C</div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            

            <div className="micr_char"></div>
          

            <div className="micr_char">A</div>
            <div className="micr_char">{this.props.branchInformation.branchNum[0]}</div>
            <div className="micr_char">{this.props.branchInformation.branchNum[1]}</div>
            <div className="micr_char">{this.props.branchInformation.branchNum[2]}</div>
            <div className="micr_char">{this.props.branchInformation.branchNum[3]}</div>
            <div className="micr_char">{this.props.branchInformation.branchNum[4]}</div>
            <div className="micr_char">D</div>
            {this.props.accountInformation.institutionNumber < 10 ? 
            <><div className="micr_char">0</div>
            <div className="micr_char">0</div>
            <div className="micr_char">{this.props.accountInformation.institutionNumber}</div></>: (
              <div className="micr_char">{this.props.accountInformation.institutionNumber}</div>
            )}
          
            {/* <div className="micr_char">{this.props.accountInformation.institutionNumber[0]}</div>
            <div className="micr_char">{this.props.accountInformation.institutionNumber[1]}</div>
            <div className="micr_char">{this.props.accountInformation.institutionNumber[2]}</div> */}
            <div className="micr_char">A</div>
            


            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char">{this.props.accountInformation.accountNumber[0]}</div>
            <div className="micr_char">{this.props.accountInformation.accountNumber[1]}</div>
            <div className="micr_char">D</div>
            <div className="micr_char">{this.props.accountInformation.accountNumber[2]}</div>
            <div className="micr_char">{this.props.accountInformation.accountNumber[3]}</div>
            <div className="micr_char">{this.props.accountInformation.accountNumber[4]}</div>
            <div className="micr_char">{this.props.accountInformation.accountNumber[5]}</div>
            <div className="micr_char">{this.props.accountInformation.accountNumber[6]}</div>
            <div className="micr_char">C</div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            


            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
            <div className="micr_char"></div>
          </div>
          <div className = "micr_space"></div>
        </div>
      </div>
      <div className = "cheque_blank">
        <div className = "scissor">&#9986;</div>
        <div className = "scissor2">&#9986;</div>
        <div className = "cheque_blank_left">{this.props.personalInformation.name}</div>
        <div className = "cheque_blank_right">{this.props.chequeInformation.chequeNumber}</div>
      </div>
      <div className = "cheque_blank cheque_blank--bottom">
        <div className = "cheque_blank_bottom_top">
          <div className = "cheque_blank_left">{this.props.personalInformation.name}
          <div className = "cheque_blank_id">{this.props.personalInformation.id}</div>
          </div>
          <div className = "cheque_blank_right">{this.props.chequeInformation.chequeNumber}</div>
        </div>
        <div className = "cheque_blank_bottom_bottom">
          <div className = "cheque_blank_id"></div>
          <div><QRCode value = {this.props.personalInformation.id} size = "60"/></div>
        </div>
      </div>
    </div>
    ) 
  }
}