import React, { useContext } from "react"
import { Route } from "react-router-dom"
import LoadingToRedirect from "./LoadingToRedirect"

import StateContext from '../../StateContext'

const UserRoute = ({ children, ...rest }) => {
  const appState = useContext(StateContext)

  return appState.loggedIn && appState.user ? <Route {...rest} /> : <LoadingToRedirect />
}

export default UserRoute
