import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/HomeGuestFooter/Footer'

import LandingPage from '../../components/LandingPage/LandingPage'
import Title from '../../components/Title/Title'

const HomeGuest = () => {
	return (
		<Title title = "Home">
		<LandingPage>
			<div className="col-lg-10 align-self-end">
				<h1 className="text-uppercase text-white font-weight-bold">Making your secure documents digitally signed!</h1>
				<hr className="divider my-4" />
			</div>
			<div className="col-lg-8 align-self-baseline">
				<p className="text-white-75 font-weight-light mb-5 desc">Through this app, you can easily transfer, receive, and print any type of standard business cheques in a much secure way. The cheques will be digitally signed through our application and can be simply transferred to any users registered with our application.</p>
				<div><Link className="btn btn-primary btn-xl js-scroll-trigger" to="/login">Login/ Sign up</Link></div>
				<div className = "verifyBtn"><Link className="btn btn-success btn-xl js-scroll-trigger" to="/verify">Verify a Check</Link></div>
			</div>
			<div className="col-lg-10 align-self-end">
				<Footer> </Footer>
			</div>
		</LandingPage>
		</Title>
		)
}

export default HomeGuest