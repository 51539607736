import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import Logo from './cryptochecks-white-top-logo.png'

import './SideBar.css'

import { signout } from '../../helpers/auth'

import DispatchContext from '../../DispatchContext'

const SideBar = ({ page }) => {
    const appDispatch = useContext(DispatchContext)

    const signoutHandler = () => {
        signout(() => {
            appDispatch({ type: "logout" })
        })
    }

    const toggleHandler = () => {
        document.querySelector(".accordion").classList.toggle('toggled')
    }

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <div className="logo">
                <img className = "logoImg" src= {Logo} alt="Logo"/>
                </div>
            </Link>
            <hr className="sidebar-divider my-0"/>
            <li className = {`nav-item ${page === "home" ? "active": ''}`}>
                <Link className="nav-link" to="/">
                    <i className="fas fa-wrench"></i>
                    <span>Dashboard</span>
                </Link>
            </li>
            <li className = {`nav-item ${page === "info" ? "active": ''}`}>
                <Link className="nav-link" to="/info">
                    <i className="fas fa-cogs"></i>
                    <span>My Information</span>
                </Link>
            </li>
            <hr className="sidebar-divider"/>
            <div className="sidebar-heading">Checks</div>
            <li className="nav-item" className = {`nav-item ${page === "writeCheck" ? "active": ''}`}>
                <Link className="nav-link" to="/print-check">
                    <i className="fas fa-pen"></i>
                    <span>Write a check</span>
                </Link>
            </li>
            <li className = {`nav-item ${page === "viewCheck" ? "active": ''}`}>
                <Link className="nav-link" to="/printed-checks">
                    <i className="fas fa-book"></i>
                    <span>Check Log</span>
                </Link>
            </li>
            <li className = {`nav-item ${page === "verifyCheck" ? "active": ''}`}>
                <Link className="nav-link" to="/verify">
                    <i className="fas fa-check"></i>
                    <span>Verify a check</span>
                </Link>
            </li>        


            <hr className="sidebar-divider"/>

            <div className="sidebar-heading">Accounts</div>

            <li className = {`nav-item ${page === "newAccount" ? "active": ''}`}>
                <Link className="nav-link" to="/new-account">
                    <i className="fas fa-money-check"></i>
                    <span>New account</span>
                </Link>
            </li>
            <li className = {`nav-item ${page === "viewAccount" ? "active": ''}`}>
                <Link className="nav-link" to="/accounts">
                    <i className="fas fa-money-bill-alt"></i>
                    <span>View accounts</span>
                </Link>
            </li>






            <hr className="sidebar-divider my-0"/>
            <li className="nav-item" onClick = { signoutHandler }>
                <Link className="nav-link nav-item--logout" to="/">
                    <i className="fas fa-sign-out-alt"></i>
                    <span className = "nav-item--logout">Logout</span>
                </Link>
            </li>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle" onClick = { toggleHandler }></button>
            </div>
        </ul>
    )
}

export default SideBar