import React from 'react'

import SideBar from '../../components/SideBar/SideBar'
import TopBar from '../../components/TopBar/TopBar'
import Footer from '../../components/Footer/Footer'

const UserPage = ({ children, page }) => {
	return (
		<div id = "wrapper">
			<SideBar page = { page }/>     
			<div id="content-wrapper" className="d-flex flex-column">
				<div id="content">
					<TopBar />
					{ children }
				</div>
				<Footer />
			</div>
		</div>
	)
}

export default UserPage