import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import './Accounts.css'

import UserPage from '../UserPage/UserPage'
import { getCookie } from '../../helpers/auth'
import DispatchContext from '../../DispatchContext'
import Title from '../../components/Title/Title'

const Accounts = () => {
	const appDispatch = useContext(DispatchContext)
	const [myAccounts, setMyAccounts] = useState([])

	useEffect(() => {
		getAccounts()
	}, [])

	


	const getAccounts = async () => {
		const token = getCookie("token")
		setMyAccounts([])
		try {
			appDispatch({type: "loadingOn"})
			let userAccounts = await Axios.get(`${process.env.REACT_APP_API}/accounts`, { headers: {
				Authorization: token 
			}})
			appDispatch({type: "loadingOff"})
			if (userAccounts.data.accounts) {
				userAccounts.data.accounts.map((account) => {
					console.log("Account is :", account)
					// Changing account title from account.bank_id.InstitutionName to account.name
					account.title = `${account.name}-${account.account_num}`
					setMyAccounts((prev) => prev.concat(account))
				})
			}
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	const deleteHandler = async (accountId) => {
		const token = getCookie("token")
		let result = window.confirm("Are you sure to delete?")
		if (result) {
			appDispatch({type: "loadingOn"})
			try {
				const response = await Axios.delete(`${process.env.REACT_APP_API}/accounts/remove/${accountId}`, { 
					headers: {
						Authorization: token
					}
				})
				appDispatch({type: "loadingOff"})
				appDispatch({type: "flashMessage", value: "Your account information has been successfully deleted!"})
				setMyAccounts((prev) => prev.filter(account => account._id !== accountId))
			} catch (err) {
				appDispatch({type: "loadingOff"})
				console.log(err)
			}
		}
	}

	return (
		<Title title = "Acconts">
		<UserPage page = "viewAccount">
		<div className="card-body">
        <div className="table-responsive">
		<table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
		<thead>
			<tr>
				<th>Account Title</th>
				<th>Update Account</th>
				<th>Delete Account</th>
			</tr>
		</thead>
		<tbody>
		{myAccounts.map((account, idx) => {
			return (
			<tr>
				<td>{account.title}</td>
				<td>
					<Link to= {`/accounts/update/${account._id}`} className="btn btn-primary btn-icon-split groupBtns_btn">
					<span className="text text--iconBtn">Update Account</span>
					</Link>
				</td>
				<td>
					<button className="btn btn-warning btn-icon-split groupBtns_btn">
					<span onClick = {() => deleteHandler(account._id)} className="text text--iconBtn">Delete Account</span>
					</button>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		</div>
		</UserPage>
		</Title>
		)
}

export default Accounts