import React, { useEffect, useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { CSSTransition } from "react-transition-group"
import Axios from 'axios'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import './Auth.css'

import DispatchContext from '../../DispatchContext'
import StateContext from '../../StateContext'
import LandingPage from '../../components/LandingPage/LandingPage'
import Title from '../../components/Title/Title'

const ForgotPassword = ({ history }) => {

	const appDispatch = useContext(DispatchContext)
	const appState = useContext(StateContext)

	useEffect(() => {
		let intended = history.location.state;
		if (appState.loggedIn && appState.user) {
			history.push("/")
		}
	}, [])

	const reMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


	const initialState = {
		email: {
			value: "",
			hasErrors: false,
			message: "",
		},
		submitCount: 0
	}

	const loginReducer = (draft, action) => {
		switch (action.type) {
			case "emailImmediately":
			draft.email.hasErrors = false
			draft.email.value = action.value
			return

			case "emailAfterDelay":
			if (!reMail.test(draft.email.value)) {
				draft.email.hasErrors = true
				draft.email.message = "You must provide a valid email address."
			}
			return

			case "submitForm":
			if (!draft.email.hasErrors) {
				draft.submitCount++
			}
			return
		}
	}

	const [state, dispatch] = useImmerReducer(loginReducer, initialState)


	useEffect(() => {
		if (state.email.value) {
			const delay = setTimeout(() => dispatch({type: "emailAfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.email.value])


	useEffect(() => {
		if (state.submitCount) {
			userSubmit()
		}
	}, [state.submitCount])

	const userSubmit = async () => {
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.post(`${process.env.REACT_APP_API}/password/forgot`, {
				email: state.email.value
			})
			appDispatch({type: "loadingOff"})
			appDispatch({type: "flashMessage", value: response.data.message})
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Email is not registered with our application" })
			console.log(err)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch({type: "emailImmediately", value: state.email.value})
		dispatch({type: "emailAfterDelay", noRequest: true})
		dispatch({type: "submitForm"})
	}

	return (
		<Title title = "Forgot Password">
		<LandingPage>
		<div className="container">


		<div className="row justify-content-center">

		<div className="col-xl-10 col-lg-12 col-md-9">

		<div className="card o-hidden border-0 shadow-lg my-5">
		<div className="card-body p-0">

		<div className="row">
		<div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
		<div className="col-lg-6">
		<div className="p-5">
		<div className="text-center">
		<h1 className="h4 text-gray-900 mb-4">Forgot Password!</h1>
		</div>
		<form className="user" onSubmit = { submitHandler }>
		<div className="form-group inputContainer">
		<label>Email</label>
		<input onChange = { e => dispatch({type: "emailImmediately", value: e.target.value}) } type="email" className="form-control form-control-user"
		id="exampleInputEmail" aria-describedby="emailHelp"
		/>
		<CSSTransition in={state.email.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.email.message}</div>
		</CSSTransition>
		</div>
		<button type = "submit" className="btn btn-primary btn-user btn-block">
		Submit
		</button>
		<hr/>
		</form>
		<div className="text-center">
		<Link className="small" to="/register">Create an Account!</Link>
		</div>
		</div>
		</div>
		</div>

		</div>
		</div>

		</div>

		</div>

		</div>
		</LandingPage>
		</Title>
		)
}

export default withRouter(ForgotPassword)