import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import { useImmerReducer } from 'use-immer'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ToWords } from 'to-words'
import { withRouter } from 'react-router-dom'

import './PrintCheque.css'

import StateContext from '../../StateContext'
import DispatchContext from '../../DispatchContext'

import { getCookie } from '../../helpers/auth'
import PrintQ from '../../components/PrintQ/PrintQ'

const ChequeQuestions = ({ online, history }) => {
	const toWords = new ToWords()
	const appState = useContext(StateContext)
	const appDispatch = useContext(DispatchContext)

	const formatDate = (date, option = 1) => {
    	var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    	if (month.length < 2) 
        	month = '0' + month;
    	if (day.length < 2) 
        	day = '0' + day;

    	if (option === 2) {
        	return [month, day, year].join('-');
        } else {
        	return [day, month, year].join('-');
        }
	}

	const [sendOnline, setSendOnline] = useState(online)
	useEffect(() => {
		if (sendOnline !== online) {
			setSendOnline({
      		sendOnline: online
    		})
		}
	}, [online])

	const initialState = {
		accounts: [],
		payees: [],
		templates: [],
		templateUrl: '',
		amount: {value: '', hasError: false, message: ''},
		amountWord: '',
		payee: {value: '', id: '', hasError: false, message: ''},
		bankAcc: {value: '-1', hasError: false, message: ''},
		depDate: new Date(),
		savedDepDate: formatDate(new Date(), 1),
		memo: {value: '', hasError: false, message: ''},
		dateFormat: {value: '1', hasError: false, message: ''},
		signInformation: '',
		signature: '',
		nameAppeared: {
			value: ''
		},
		addressAppeared: {
			value: ''
		},
		logoAppeared: {
			value: ''
		},
		phoneAppeared: {
			value: ''
		},
		postalcodeAppeared: {
			value: ''
		},
		checkId: "",
		submitCount: 0
	}

	const [currentUser, setCurrentUser] = useState()

	const addZeroes = (num) => {
		// Convert input string to a number and store as a variable.
	    var value = Number(num);      
		// Split the input string into two arrays containing integers/decimals
	    var res = num.split(".");     
		// If there is no decimal point or only one decimal place found.
	    if(res.length == 1 || res[1].length < 3) { 
		// Set the number to two decimal places
	        value = value.toFixed(2);
    	}
	// Return updated or original number.
		return value;
	}


	useEffect(() => {
		getUserInfo()
	}, [])

	const getUserInfo = async () => {
		const token = getCookie("token")
		try {
			appDispatch({type: "loadingOn"})
			let userInfo = await Axios.get(`${process.env.REACT_APP_API}/info`, { 
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
			setCurrentUser(userInfo.data)
			dispatch({type: "nameAppearedImmediately", value: Boolean(userInfo.data.name_appeared)})
			dispatch({type: "addressAppearedImmediately", value: Boolean(userInfo.data.address_appeared)})
			dispatch({type: "logoAppearedImmediately", value: Boolean(userInfo.data.logo_appeared)})
			dispatch({type: "phoneAppearedImmediately", value: Boolean(userInfo.data.phone_appeared)})
			dispatch({type: "postalcodeAppearedImmediately", value: Boolean(userInfo.data.postalcode_appeared)})
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	const [writeCheck, setWriteCheck] = useState(false)

	const writeRecucer = (draft, action) => {
		switch (action.type) {
			case "addAccount":
			draft.accounts.push(action.value)
			break

			case "clearAccount":
			draft.accounts = []
			break

			case "addPayee":
			draft.payees.push(action.value)
			break

			case "clearPayee":
			draft.payees = []
			break

			case "addTemplate":
			draft.templates.push(action.value)
			break

			case "clearTemplate":
			draft.payees = []
			break

			case "temlateChange":
			draft.templateUrl = action.value
			break

			case "amountChange":
			draft.amount.hasErrors = false
			draft.amount.message = ''
			draft.amount.value = action.value
			if (draft.amount.value.length >= 1) {
				draft.amountWord = toWords.convert(parseFloat(action.value))
			}
			if (!draft.amount.value.length){
				draft.amountWord = ""
			}
			break

			case "amountOnSubmit":
			if (draft.amount.value.length < 1) {
				draft.amount.hasErrors = true
				draft.amount.message = "Amount is required."
			}
			break

			case "payeeChange":
			draft.payee.hasErrors = false
			draft.payee.message = ''
			draft.payee.value = action.value
			if (action.id) {
				draft.payee.id = action.id
			}
			break

			case "payeeOnSubmit":
			if (draft.payee.value.length < 3) {
				draft.payee.hasErrors = true
				draft.payee.message = "Payee must be at least 3 characters."
			}
			break

			case "bankAccChange":
			draft.bankAcc.hasErrors = false
			draft.bankAcc.message = ''
			draft.bankAcc.value = action.value
			break

			case "bankAccOnSubmit":
			if (draft.bankAcc.value < 1) {
				draft.bankAcc.hasErrors = true
				draft.bankAcc.message = "Please choose a valid bank account."
			}
			break

			case "depDateChange":
			draft.depDate = action.value
			draft.savedDepDate = formatDate(action.value, parseInt(draft.dateFormat.value))
			break

			case "savedDepDateChange":
			draft.savedDepDate = action.value
			break

			case "memoChange":
			draft.memo.hasErrors = false
			draft.memo.message = ''
			draft.memo.value = action.value
			break

			case "dateFormatChange":
			draft.dateFormat.hasErrors = false
			draft.dateFormat.message = ''
			draft.dateFormat.value = action.value
			draft.savedDepDate = formatDate(draft.depDate, parseInt(draft.dateFormat.value))
			break

			case "dateFormatOnSubmit":
			if (draft.dateFormat.value < 1) {
				draft.dateFormat.hasErrors = true
				draft.dateFormat.message = "Please choose a valid date format."
			}
			break

			case "getSignInfo":
				draft.signInformation = action.value
			break

			case "sign":
				draft.signature = action.value
			break

			case "nameAppearedImmediately":
				draft.nameAppeared.value = action.value
        	break

        	case "addressAppearedImmediately":
				draft.addressAppeared.value = action.value
        	break

        	case "logoAppearedImmediately":
				draft.logoAppeared.value = action.value
        	break

        	case "phoneAppearedImmediately":
				draft.phoneAppeared.value = action.value
        	break

        	case "postalcodeAppearedImmediately":
				draft.postalcodeAppeared.value = action.value
        	break

        	case "checkIdImmediately":
				draft.checkId = action.value
        	break

			case "submitForm":
			if (!draft.amount.hasErrors && !draft.payee.hasErrors && !draft.bankAcc.hasErrors && !draft.memo.hasErrors && !draft.dateFormat.hasErrors) {
				draft.submitCount++
			}
			break
		}
	}

	const [state, dispatch] = useImmerReducer(writeRecucer, initialState)

	const [currentAccount, setCurrentAccount] = useState()

	useEffect(() => {
		getCurrentAccount()
	}, [state.bankAcc.value])


	const getCurrentAccount = async () => {
		const token = getCookie("token")
		if (state.bankAcc.value !== "-1") {
			try {
				appDispatch({type: "loadingOn"})
				let curAcc = await Axios.get(`${process.env.REACT_APP_API}/accounts/${state.bankAcc.value}`, {
					headers: {
						Authorization: token
					}
				})
				appDispatch({type: "loadingOff"})
				curAcc.data.check_num = padToFour(parseInt(curAcc.data.check_num))
				setCurrentAccount(curAcc.data)
				console.log(curAcc.data)
			} catch (err) {
				appDispatch({type: "loadingOff"})
				console.log(err)
			}
		}
	}

	useEffect(() => {
		if (online) {
			getPayees() 
		}
	}, [])

	const getPayees = async () => {
		const token = getCookie("token")
		dispatch({type: "clearPayee"})
		try {
			appDispatch({type: "loadingOn"})
			let res = await Axios.get(`${process.env.REACT_APP_API}/payees`, {
				headers: {
					Authorization: token 
				}
			})
			appDispatch({type: "loadingOff"})
			res.data.payees.map(payee => {
				dispatch({type: "addPayee", value: { id: payee.payee_id, name: payee.name, email: payee.email }})
			}) 
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	useEffect(() => {
		getAccounts()
	}, [])

	const getAccounts = async () => {
		const token = getCookie("token")
		dispatch({type: "clearAccount"})
		try {
			appDispatch({type: "loadingOn"})
			let res = await Axios.get(`${process.env.REACT_APP_API}/accounts`, {
				headers: {
					Authorization: token 
				}
			})
			appDispatch({type: "loadingOff"})
			res.data.accounts.map(acc => {
				// changing acccount name to acc.name from acc.bank_id.InstitutionName
				console.log(acc)
				dispatch({type: "addAccount", value: { id: acc._id, name: `${acc.name}-${acc.account_num}` }})
			}) 
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	useEffect(() => {
		getTemplates()
	}, [])

	const getTemplates = async () => {
		const token = getCookie("token")
		dispatch({type: "clearTemplate"})
		let tempNum = 0
		try {
			appDispatch({type: "loadingOn"})
			let res = await Axios.get(`${process.env.REACT_APP_API}/templates`, {
				headers: {
					Authorization: token 
				}
			})
			appDispatch({type: "loadingOff"})
			console.log(res)
			res.data.templates.map(temp => {
				tempNum = tempNum + 1
				dispatch({type: "addTemplate", value: { id: temp._id, url: temp.bgImgUrl, name: temp.name }})
			}) 
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	useEffect(() => {
		if (state.submitCount) {
			infoSubmit()
			signCheck()
		}
	}, [state.submitCount])

	const infoSubmit = () => {
		console.log(state)
		setWriteCheck(true)
	}

	const signCheck = async () => {
		console.log("Current account in FE is ", currentAccount)
		console.log("State in FE is ", state)
		let allInformation = {
			personalInformation: {
				id: currentUser._id,
				name: currentAccount.name,
				address: currentAccount.address,
				city: currentAccount.city,
				province: currentAccount.province,
				postalCode: currentAccount.postalcode,
				logo: currentAccount.logo,
				phone: currentAccount.phone
			},
			chequeInformation: {
				chequeNumber: currentAccount.check_num,
				date: state.savedDepDate,
				dateFormat: state.dateFormat.value,
				payee: state.payee.value,
				amount: state.amount.value,
				amountWord: state.amountWord,
				templateUrl: state.templateUrl,
			},
			branchInformation: {
				// bank: currentAccount.bank_id.InstitutionName,
				bank: currentAccount.name,
				url: currentAccount.bank_id.LogoUrl,
				branchAddress: currentAccount.branch_id.Address,
				branchProvince: currentAccount.branch_id.Province,
				branchCity: currentAccount.branch_id.City,
				branchPostalCode: currentAccount.branch_postalcode,
				branchNum: currentAccount.branch_num,
				branchName: currentAccount.branch_id.BranchName
			},
			accountInformation: {
				institutionNumber: currentAccount.bank_id.toString(),
				accountNumber: currentAccount.account_num
			},
			additionalInformation: {
				memo: state.memo.value,
				online: online,
				payeeId: state.payee.id
			},
			appearedInformation: {
				name: state.nameAppeared.value,
				address: state.addressAppeared.value,
				logo: state.logoAppeared.value,
				postalcode: state.postalcodeAppeared.value,
				phone: state.phoneAppeared.value
			}
		}

		try {
			const verifiableData = {
				checkNum: allInformation.chequeInformation.chequeNumber,
				payerId: allInformation.personalInformation.id,
				payee: allInformation.chequeInformation.payee,
				amount: addZeroes(allInformation.chequeInformation.amount),
				date: allInformation.chequeInformation.date,
				accountNumber: allInformation.accountInformation.accountNumber,
				transitNumber: allInformation.branchInformation.branchNum,
				institutionNum: allInformation.accountInformation.institutionNumber,
			}
			appDispatch({type: "loadingOn"})
			console.log("Verifiable data when signing is ", JSON.stringify(verifiableData))
			let response = await Axios.post(`${process.env.REACT_APP_API}/sign-document`, {
				verifiableData: verifiableData
			})
			appDispatch({type: "loadingOff"})
			dispatch({ type: 'getSignInfo', value: JSON.stringify(allInformation) })
			console.log(JSON.stringify(JSON.stringify(verifiableData)))
			dispatch({ type: 'sign', value: response.data.signature })
			saveCheckHandler(JSON.stringify(allInformation), response.data.signature, state.payee.id)
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
			console.log(err)
		}
	}

	const submitDataHandler = (e) => {
		e.preventDefault()
		dispatch({type: "amountChange", value: state.amount.value})
		dispatch({type: "payeeChange", value: state.payee.value})
		dispatch({type: "bankAcc", value: state.bankAcc.value})
		dispatch({type: "depDateChange", value: state.depDate})
		dispatch({type: "memoChange", value: state.memo.value})
		dispatch({type: "dateFormatChange", value: state.dateFormat.value})

		dispatch({type: "amountOnSubmit", value: state.amount.value})
		dispatch({type: "payeeOnSubmit", value: state.payee.value})
		dispatch({type: "bankAccOnSubmit", value: state.bankAcc.value})
		dispatch({type: "dateFormatOnSubmit", value: state.dateFormat.value})
		
		dispatch({type: "submitForm"})
	}

	let padToFour = number => number <= 9999 ? `000${number}`.slice(-4) : number;

	const saveCheckHandler = async (signInfo, sign, payeeId) => {
		let logoUrl = ''
		const token = getCookie("token")
		let sentData
		if (currentAccount.logo && currentAccount.logo.url && currentAccount.logo.url.length > 3) {
				logoUrl = currentAccount.logo.url
			}
		if (!online) {
			sentData = {
				signature: signInfo,
				hashed_signature: sign,
				logo_url: logoUrl
			}
		} else {
			sentData = {
				signature: signInfo,
				hashed_signature: sign,
				logo_url: logoUrl,
				payee_id: payeeId
			}
		}
		try {
			appDispatch({type: "loadingOn"})
			let res = await Axios.post(`${process.env.REACT_APP_API}/check/new`,sentData, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
			dispatch({type: "checkIdImmediately", value: res.data._id})
		} catch (err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}

		try {
			appDispatch({type: "loadingOn"})
			let res = await Axios.post(`${process.env.REACT_APP_API}/accounts/update/${state.bankAcc.value}`, {check_num: parseInt(currentAccount.check_num) + 1}, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
			history.push('/printed-checks')
		} catch (err) {
			console.log(err)
			appDispatch({type: "loadingOff"})
		}
	}

	return (
		<React.Fragment>
			<PrintQ state = { state } dispatch = { dispatch } submitDataHandler = { submitDataHandler } currentUser = {currentUser} currentAccount = { currentAccount } online = { online }  />
		</React.Fragment>
	)
}

export default withRouter(ChequeQuestions)