import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import UserPage from '../UserPage/UserPage'
import { getCookie } from '../../helpers/auth'
import DispatchContext from '../../DispatchContext'
import Title from '../../components/Title/Title'

const Payees = () => {
	const appDispatch = useContext(DispatchContext)
	const [myPayees, setMyPayees] = useState([])

	useEffect(() => {
		getPayees()
	}, [])

	


	const getPayees = async () => {
		const token = getCookie("token")
		setMyPayees([])
		try {
			appDispatch({type: "loadingOn"})
			let userPayees = await Axios.get(`${process.env.REACT_APP_API}/payees`, { headers: {
				Authorization: token 
			}})
			appDispatch({type: "loadingOff"})
			if (userPayees.data.payees) {
				userPayees.data.payees.map((payee) => {
					setMyPayees((prev) => prev.concat(payee))
				})
			}
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	const deleteHandler = async (payeeId) => {
		const token = getCookie("token")
		let result = window.confirm("Are you sure to delete?")
		if (result) {
			appDispatch({type: "loadingOn"})
			try {
				const response = await Axios.delete(`${process.env.REACT_APP_API}/payees/remove/${payeeId}`, { 
					headers: {
						Authorization: token
					}
				})
				appDispatch({type: "loadingOff"})
				appDispatch({type: "flashMessage", value: "Payee information has been successfully deleted!"})
				setMyPayees((prev) => prev.filter(payee => payee._id !== payeeId))
			} catch (err) {
				appDispatch({type: "loadingOff"})
				console.log(err)
			}
		}
	}

	return (
		<Title title = "Payees">
		<UserPage page = "viewPayee">
		<div className="card-body">
        <div className="table-responsive">
		<table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
		<thead>
			<tr>
				<th>Email</th>
				<th>Update Payee</th>
				<th>Delete Payee</th>
			</tr>
		</thead>
		<tbody>
		{myPayees.map((payee, idx) => {
			return (
			<tr>
				<td>{payee.email}</td>
				<td>
					<Link to= {`/payees/update/${payee._id}`} className="btn btn-primary btn-icon-split groupBtns_btn">
					<span className="text text--iconBtn">Update Payee</span>
					</Link>
				</td>
				<td>
					<button className="btn btn-warning btn-icon-split groupBtns_btn">
					<span onClick = {() => deleteHandler(payee._id)} className="text text--iconBtn">Delete Payee</span>
					</button>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		</div>
		</UserPage>
		</Title>
		)
}

export default Payees