import React, { useEffect, useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { CSSTransition } from "react-transition-group"
import Axios from 'axios'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import './Auth.css'

import DispatchContext from '../../DispatchContext'
import StateContext from '../../StateContext'
import { authenticate, isAuth } from '../../helpers/auth'
import LandingPage from '../../components/LandingPage/LandingPage'
import Title from '../../components/Title/Title'

const ResetPassword = ({ history, match }) => {

	const appDispatch = useContext(DispatchContext)
	const appState = useContext(StateContext)

	useEffect(() => {
		let intended = history.location.state;
		if (appState.loggedIn && appState.user) {
			history.push("/")
		}
	}, [])

	const reMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


	const initialState = {
		password: {
			value: "",
			hasErrors: false,
			message: "",
		},
		password2: {
			value: "",
			hasErrors: false,
			message: "",
		},
		submitCount: 0
	}

	const loginReducer = (draft, action) => {
		switch (action.type) {
			case "passwordImmediately":
			draft.password.hasErrors = false
			draft.password.value = action.value
			return

			case "passwordAfterDelay":
			if (draft.password.value.length < 6) {
				draft.password.hasErrors = true
				draft.password.message = "Password must be at least 6 characters."
			}
			return

			case "password2Immediately":
			draft.password2.hasErrors = false
			draft.password2.value = action.value
			return

			case "password2AfterDelay":
			if (draft.password2.value !== draft.password.value) {
				draft.password2.hasErrors = true
				draft.password2.message = "Passwords do not match."
			}
			return

			case "submitForm":
			if (!draft.password.hasErrors && !draft.password2.hasErrors ) {
				draft.submitCount++
			}
			return
		}
	}

	const [state, dispatch] = useImmerReducer(loginReducer, initialState)


	useEffect(() => {
		if (state.password.value) {
			const delay = setTimeout(() => dispatch({type: "passwordAfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.password.value])

	useEffect(() => {
		if (state.password2.value) {
			const delay = setTimeout(() => dispatch({type: "password2AfterDelay"}), 800)
			return () => clearTimeout(delay)
		}
	}, [state.password2.value])


	useEffect(() => {
		if (state.submitCount) {
			userSubmit()
		}
	}, [state.submitCount])

	const userSubmit = async () => {
		const token = match.params.token
		console.log(token)
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.post(`${process.env.REACT_APP_API}/password/reset`, {
				newPassword: state.password.value,
				resetPasswordLink: token
			})
			appDispatch({type: "loadingOff"})
			history.push('/login')
			appDispatch({type: "flashMessage", value: response.data.message})
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Something went wrong; please try later" })
			console.log(err)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch({type: "passwordImmediately", value: state.password.value})
		dispatch({type: "passwordAfterDelay", noRequest: true})
		dispatch({type: "password2Immediately", value: state.password2.value})
		dispatch({type: "password2AfterDelay"})
		dispatch({type: "submitForm"})
	}

	return (
		<Title title = "login">
		<LandingPage>
		<div className="container">


		<div className="row justify-content-center">

		<div className="col-xl-10 col-lg-12 col-md-9">

		<div className="card o-hidden border-0 shadow-lg my-5">
		<div className="card-body p-0">

		<div className="row">
		<div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
		<div className="col-lg-6">
		<div className="p-5">
		<div className="text-center">
		<h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
		</div>
		<form className="user" onSubmit = { submitHandler }>
		<div className="form-group inputContainer">
		<label>Password</label>
		<input onChange = { e => dispatch({type: "passwordImmediately", value: e.target.value}) } type="password" className="form-control form-control-user"
		id="exampleInputPassword"
		/>
		<CSSTransition in={state.password.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.password.message}</div>
		</CSSTransition>
		</div>
		<div className="form-group inputContainer">
		<label>Repeat Password</label>
		<input onChange = { e => dispatch({type: "password2Immediately", value: e.target.value}) } type="password" className="form-control form-control-user"
		id="exampleInputPassword"/>
		<CSSTransition in={state.password2.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.password2.message}</div>
		</CSSTransition>
		</div>
		<button type = "submit" className="btn btn-primary btn-user btn-block">
		Save Password
		</button>
		</form>
		<hr/>
		<div className="text-center">
		<Link className="small" to="/login">Login</Link>
		</div>
		<div className="text-center">
		<Link className="small" to="/register">Create an Account!</Link>
		</div>
		</div>
		</div>
		</div>

		</div>
		</div>

		</div>

		</div>

		</div>
		</LandingPage>
		</Title>
		)
}

export default withRouter(ResetPassword)