import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import StateContext from "./StateContext";
import DispatchContext from "./DispatchContext";

import { getCookie } from "./helpers/auth";

import HomeGuest from "./pages/Home/HomeGuest";
import Home from "./pages/Home/Home";
import Register from "./pages/Auth/Register";
import Activate from "./pages/Auth/Activate";
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import UserRoute from "./pages/routes/UserRoute";
import MyInformation from "./pages/MyInformation/MyInformation";
import UpdateInformation from "./pages/MyInformation/UpdateInformation/UpdateInformation";
import Accounts from "./pages/Accounts/Accounts";
import NewAccount from "./pages/Accounts/NewAccount/NewAccount";
import UpdateAccount from "./pages/Accounts/UpdateAccount/UpdateAccount";
import Payees from "./pages/Payees/Payees";
import NewPayee from "./pages/Payees/NewPayee/NewPayee";
import UpdatePayee from "./pages/Payees/UpdatePayee/UpdatePayee";
import PrintCheque from "./pages/PrintCheque/PrintCheque";
import SavedChecks from "./pages/SavedChecks/SavedChecks";
import ViewCheck from "./pages/SavedChecks/ViewCheck/ViewCheck";
import DepositedChecks from "./pages/DepositedChecks/DepositedChecks";
import Verify from "./pages/Verify/Verify";
import NotFound from "./pages/NotFound/NotFound";

// import AddBranches from "./helpers/AddBranches";
// import CanadianBanks from "./helpers/CanadianBanks";
// import AddTemplates from "./helpers/AddTemplates";

// <Route exact path="/admin/branches/add" component={AddBranches} />
//             <Route exact path="/admin/banks/add" component={CanadianBanks} />
//             <Route exact path="/admin/templates/add" component={AddTemplates} />

const App = () => {
  const initialState = {
    loggedIn: Boolean(getCookie("token")),
    user: JSON.parse(localStorage.getItem("user")),
    flashMessages: [],
    errorMessages: [],
    isLoading: false,
  };

  const appReducer = (draft, action) => {
    switch (action.type) {
      case "login":
        draft.loggedIn = true;
        draft.user = action.user;
        break;

      case "logout":
        draft.loggedIn = false;
        break;

      case "flashMessage":
        draft.flashMessages.push(action.value);
        break;

      case "flashMessageDelete":
        draft.flashMessages.shift();
        break;

      case "errorMessage":
        draft.errorMessages.push(action.value);
        break;

      case "errorMessageDelete":
        draft.errorMessages.shift();
        break;

      case "loadingOn":
        draft.isLoading = true;
        break;

      case "loadingOff":
        draft.isLoading = false;
        break;
      default:
        return
    }
  };

  const [state, dispatch] = useImmerReducer(appReducer, initialState);

  useEffect(() => {
    if (state.flashMessages.length) {
      const delay = setTimeout(
        () => dispatch({ type: "flashMessageDelete" }),
        5000
      );
      return () => clearTimeout(delay);
    }
  }, [state.flashMessages]);

  useEffect(() => {
    if (state.errorMessages.length) {
      const delay = setTimeout(
        () => dispatch({ type: "errorMessageDelete" }),
        5000
      );
      return () => clearTimeout(delay);
    }
  }, [state.errorMessages]);

  return (
    <div>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <div className="general_alert_box">
            <TransitionGroup component={null}>
              {state.flashMessages.length &&
                state.flashMessages.map((msg, idx) => {
                  return (
                    <CSSTransition classNames="dialog" timeout={0}>
                      <div className="alert alert--general" key={idx}>
                        {msg}
                      </div>
                    </CSSTransition>
                  );
                })}
            </TransitionGroup>

            <TransitionGroup component={null}>
              {state.errorMessages.length &&
                state.errorMessages.map((msg, idx) => {
                  return (
                    <CSSTransition classNames="dialog" timeout={0}>
                      <div
                        className="alert alert--general warningFlash"
                        key={idx}
                      >
                        {msg}
                      </div>
                    </CSSTransition>
                  );
                })}
            </TransitionGroup>
          </div>
          {state.isLoading && (
            <div className="LoadingModal">
              <div className="spinner-border loadingSPinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <Switch>
            <Route exact path="/">
              {state.loggedIn ? <Home /> : <HomeGuest />}
            </Route>

            <Route exact path="/register" component={Register} />
            <Route exact path="/users/activate/:token" component={Activate} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/password/forgot" component={ForgotPassword} />
            <Route
              exact
              path="/password/reset/:token"
              component={ResetPassword}
            />
            <UserRoute exact path="/info" component={MyInformation} />
            <UserRoute
              exact
              path="/info/update"
              component={UpdateInformation}
            />
            <UserRoute exact path="/accounts" component={Accounts} />
            <UserRoute exact path="/new-account" component={NewAccount} />
            <UserRoute
              exact
              path="/accounts/update/:id"
              component={UpdateAccount}
            />
            <UserRoute exact path="/payees" component={Payees} />
            <UserRoute exact path="/payee/new" component={NewPayee} />
            <UserRoute
              exact
              path="/payees/update/:id"
              component={UpdatePayee}
            />
            <UserRoute
              exact
              path="/print-check"
              render={(props) => <PrintCheque {...props} online={false} />}
            />
            <UserRoute
              exact
              path="/send-check"
              render={(props) => <PrintCheque {...props} online={true} />}
            />
            <UserRoute exact path="/printed-checks" component={SavedChecks} />
            <UserRoute exact path="/checks/view/:id" component={ViewCheck} />
            <UserRoute
              exact
              path="/deposited-checks"
              component={DepositedChecks}
            />
            <Route exact path="/verify" component={Verify} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </DispatchContext.Provider>
      </StateContext.Provider>
    </div>
  );
};

export default App;
