import React, { useEffect, useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { CSSTransition } from "react-transition-group"
import Axios from 'axios'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import './Auth.css'

import DispatchContext from '../../DispatchContext'
import StateContext from '../../StateContext'
import { authenticate, isAuth } from '../../helpers/auth'
import LandingPage from '../../components/LandingPage/LandingPage'
import Title from '../../components/Title/Title'

const Login = ({ history }) => {

	const appDispatch = useContext(DispatchContext)
	const appState = useContext(StateContext)

	useEffect(() => {
		let intended = history.location.state;
		if (appState.loggedIn && appState.user) {
			history.push("/")
		}
	}, [])

	const reMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


	const initialState = {
		email: {
			value: "",
			hasErrors: false,
			message: "",
		},
		password: {
			value: "",
			hasErrors: false,
			message: "",
		},
		submitCount: 0
	}

	const loginReducer = (draft, action) => {
		switch (action.type) {
			case "emailImmediately":
				draft.email.hasErrors = false
				draft.email.value = action.value
				return

			case "emailAfterDelay":
				if (!reMail.test(draft.email.value)) {
					draft.email.hasErrors = true
					draft.email.message = "You must provide a valid email address."
				}
				return

			case "passwordImmediately":
				draft.password.hasErrors = false
				draft.password.value = action.value
				return

			case "passwordAfterDelay":
				if (draft.password.value.length < 6) {
					draft.password.hasErrors = true
					draft.password.message = "Password must be at least 6 characters."
				}
				return

			case "submitForm":
				if (!draft.email.hasErrors && !draft.password.hasErrors) {
					draft.submitCount++
				}
				return
		}
	}

	const [state, dispatch] = useImmerReducer(loginReducer, initialState)


	useEffect(() => {
		if (state.email.value) {
			const delay = setTimeout(() => dispatch({ type: "emailAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.email.value])

	useEffect(() => {
		if (state.password.value) {
			const delay = setTimeout(() => dispatch({ type: "passwordAfterDelay" }), 800)
			return () => clearTimeout(delay)
		}
	}, [state.password.value])


	useEffect(() => {
		if (state.submitCount) {
			userSubmit()
		}
	}, [state.submitCount])

	const userSubmit = async () => {
		try {
			appDispatch({ type: "loadingOn" })
			let response = await Axios.post(`${process.env.REACT_APP_API}/login`, {
				email: state.email.value,
				password: state.password.value
			})
			appDispatch({ type: "loadingOff" })
			authenticate(response, () => {
				const user = isAuth()
				if (user) {
					appDispatch({ type: "login", user: user })
					history.push('/')
				}
			})
		} catch (err) {
			appDispatch({ type: "loadingOff" })
			appDispatch({ type: "errorMessage", value: "Email and password does not match!" })
			console.log(err)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch({ type: "emailImmediately", value: state.email.value })
		dispatch({ type: "emailAfterDelay", noRequest: true })
		dispatch({ type: "passwordImmediately", value: state.password.value })
		dispatch({ type: "passwordAfterDelay" })
		dispatch({ type: "submitForm" })
	}

	const sendGoogleToken = tokenId => {
		appDispatch({ type: "loadingOn" })
		Axios
			.post(`${process.env.REACT_APP_API}/googlelogin`, {
				idToken: tokenId
			})
			.then(res => {
				appDispatch({ type: "loadingOff" })
				console.log(res.data)
				informParent(res)
			})
			.catch(error => {
				appDispatch({ type: "loadingOff" })
				console.log('GOOGLE SIGNIN ERROR', error.response);
			})
	}

	const informParent = response => {
		authenticate(response, () => {
			const user = isAuth()
			if (user) {
				appDispatch({ type: "login", user: user })
				history.push('/')
			}
		})
	}

	const responseGoogle = response => {
		console.log(response)
		sendGoogleToken(response.tokenId)
	}

	const sendFacebookToken = (userID, accessToken) => {
		appDispatch({ type: "loadingOn" })
		Axios
			.post(`${process.env.REACT_APP_API}/facebooklogin`, {
				userID,
				accessToken
			})
			.then(res => {
				appDispatch({ type: "loadingOff" })
				console.log(res.data)
				informParent(res)
			})
			.catch(error => {
				appDispatch({ type: "loadingOff" })
				console.log('Facebook SIGNIN ERROR', error.response)
			})
	}

	const responseFacebook = response => {
		console.log(response)
		sendFacebookToken(response.userID, response.accessToken)
	}

	return (
		<Title title="login">
			<LandingPage>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-10 col-lg-12 col-md-9">
							<div className="card o-hidden border-0 shadow-lg my-5">
								<div className="card-body p-0">
									<div className="row">
										<div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
										<div className="col-lg-6">
											<div className="p-5">
												<div className="text-center">
													<h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
												</div>
												<form className="user" onSubmit={submitHandler}>
													<div className="form-group inputContainer">
														<label>Email</label>
														<input onChange={e => dispatch({ type: "emailImmediately", value: e.target.value })} type="email" className="form-control form-control-user"
															id="exampleInputEmail" aria-describedby="emailHelp"
														/>
														<CSSTransition in={state.email.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
															<div className="alert small alert--warning">{state.email.message}</div>
														</CSSTransition>
													</div>
													<div className="form-group inputContainer">
														<label>Password</label>
														<input onChange={e => dispatch({ type: "passwordImmediately", value: e.target.value })} type="password" className="form-control form-control-user"
															id="exampleInputPassword" />
														<CSSTransition in={state.password.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
															<div className="alert small alert--warning">{state.password.message}</div>
														</CSSTransition>
													</div>
													<button type="submit" className="btn btn-primary btn-user btn-block">
														Login
													</button>
													<hr />
													<GoogleLogin
														clientId={`${process.env.REACT_APP_GOOGLE_CLIENT}`}
														onSuccess={responseGoogle}
														onFailure={responseGoogle}
														cookiePolicy={'single_host_origin'}
														render={renderProps => (
															<button
																onClick={renderProps.onClick}
																disabled={renderProps.disabled}
																className="btn btn-google btn-user btn-block">
																<i className="fab fa-google fa-fw"></i> Login with Google
															</button>
														)}
													>
													</GoogleLogin>

												</form>
												<hr />
												<div className="text-center">
													<Link className="small" to="/password/forgot">Forgot Password?</Link>
												</div>
												{/* <div className="text-center">
													<Link className="small" to="/register">Create an Account!</Link>
												</div> */}
											</div>
										</div>
									</div>

								</div>
							</div>

						</div>

					</div>

				</div>
			</LandingPage>
		</Title>
	)
}

export default withRouter(Login)