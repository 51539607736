import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import UserPage from '../UserPage/UserPage'
import { getCookie } from '../../helpers/auth'
import DispatchContext from '../../DispatchContext'
import Title from '../../components/Title/Title'

const DepositedChecks = () => {
	const appDispatch = useContext(DispatchContext)
	const [myChecks, setMyChecks] = useState([])
	
	useEffect(() => {
		getChecks()
	}, [])

	const getChecks = async () => {
		const token = getCookie("token")
		setMyChecks([])
		try {
			appDispatch({type: "loadingOn"})
			let userChecks = await Axios.get(`${process.env.REACT_APP_API}/checks-dep`, { headers: {
				Authorization: token 
			}})
			appDispatch({type: "loadingOff"})
			if (userChecks.data.checks) {
				userChecks.data.checks.map((check) => {
					if (!check.payee_deleted) {
						setMyChecks((prev) => prev.concat(check))
					}
				})
			}
		} catch(err) {
			appDispatch({type: "loadingOff"})
			console.log(err)
		}
	}

	const dateFormatHandler = (num) => {
		if (num === "1") {
			return "DD-MM-YYYY"
		}
		if (num === "2") {
			return "MM-DD-YYYY"
		}
	}

	const deleteHandler = async (checkId) => {
		const token = getCookie("token")
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.post(`${process.env.REACT_APP_API}/checks/update/${checkId}`, {
				payee_deleted: true,
				viewed: true
			}, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
			appDispatch({type: "flashMessage", value: "Check has been successfully deleted!"})
			setMyChecks((prev) => prev.filter(check => check._id !== checkId))
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
			console.log(err)
		}
	}

	const viewedHandler = async (checkId) => {
		const token = getCookie("token")
		try {
			appDispatch({type: "loadingOn"})
			let response = await Axios.post(`${process.env.REACT_APP_API}/checks/update/${checkId}`, {
				viewed: true
			}, {
				headers: {
					Authorization: token
				}
			})
			appDispatch({type: "loadingOff"})
		} catch (err) {
			appDispatch({type: "loadingOff"})
			appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
			console.log(err)
		}
	}

	const addZeroes = (num) => {
		// Convert input string to a number and store as a variable.
	    var value = Number(num);      
		// Split the input string into two arrays containing integers/decimals
	    var res = num.split(".");     
		// If there is no decimal point or only one decimal place found.
	    if(res.length == 1 || res[1].length < 3) { 
		// Set the number to two decimal places
	        value = value.toFixed(2);
    	}
	// Return updated or original number.
		return value;
	}

	return (
		<Title title = "Deposited Checks">
		<UserPage page = "depCheck">
		<div className="card-body">
        <div className="table-responsive">
		<table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
		<thead>
			<tr>
				<th>Account Title</th>
				<th>Payer</th>
				<th>Deposit Date</th>
				<th>Amount</th>
				<th>Description</th>
				<th>View Check</th>
				<th>Remove Check</th>
			</tr>
		</thead>
		<tbody>
		{myChecks.map((check, idx) => {
			return (
			<tr>
				<td>{`${JSON.parse(check.signature).personalInformation.name}-${JSON.parse(check.signature).accountInformation.accountNumber}`}</td>
				<td>{JSON.parse(check.signature).personalInformation.name}</td>
				<td>{JSON.parse(check.signature).chequeInformation.date}</td>
				<td>{addZeroes(JSON.parse(check.signature).chequeInformation.amount)}</td>
				<td>{JSON.parse(check.signature).additionalInformation.memo}</td>
				<td>
					<Link to= {`/checks/view/${check._id}`} className="btn btn-primary btn-icon-split groupBtns_btn">
					<span className="text text--iconBtn">View The Check</span>
					</Link>
				</td>
				<td>
					<button className="btn btn-warning btn-icon-split groupBtns_btn">
					<span onClick = {() => deleteHandler(check._id)} className="text text--iconBtn">Delete The Check</span>
					</button>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		</div>
		</UserPage>
		</Title>
	)
}

export default DepositedChecks